import $ from 'jquery'
import 'slick-carousel/slick/slick'

if (screen.width > 768 || document.getElementById('home') !== undefined) {
  const options = (dots) => ({
    dots,
    arrows: false,
    speed: 500,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 4000
  })
  $('.slider:not(.slider_dots)').attr('hidden', false).slick(options(false));
  $('.slider.slider_dots').attr('hidden', false).slick(options(true));
}
